import React from 'react'
import { Link } from 'react-router-dom'

const MenuItems = (props) => {
  const { parentMenu, activeMenu } = props

  return (
    <React.Fragment>
      <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
        <Link to="/" className={activeMenu === '/about' ? 'active-menu' : ''}>Home</Link>
      </li>
      <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
        <Link to="/about" className={activeMenu === '/about' ? 'active-menu' : ''}>About</Link>
      </li>
      <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
        <Link href="#" as="#">Web Solutions</Link>
        <ul className="sub-menu">
          <li>
            <Link to="/web/corporate-web-design" className={activeMenu === '/software-development' ? 'active-menu' : ''}>Software Development</Link>
          </li>
          <li>
            <Link to="/web/cmd-wordpress" className={activeMenu === '/web-development' ? 'active-menu' : ''}>CMS WordPress</Link>
          </li>
          <li>
            <Link to="/web/e-commerce" className={activeMenu === '/web-development' ? 'active-menu' : ''}>E-commerce Web Development</Link>
          </li>
          <li>
            <Link to="/web/landing-page" className={activeMenu === '/web-development' ? 'active-menu' : ''}>Landing Page Development</Link>
          </li>
        </ul>
      </li>
      <li className={parentMenu === 'app' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
        <Link href="#" as="#">App Solutions</Link>
        <ul className="sub-menu">
          <li>
            <Link to="/app/mobile-app-development" className={activeMenu === '/mobile-app-development' ? 'active-menu' : ''}>Mobile App Development</Link>
          </li>
          <li>
            <Link to="/app/ui-ux-design" className={activeMenu === '/ui-ux-design' ? 'active-menu' : ''}>UI/UX Design</Link>
          </li>
          <li>
            <Link to="/app/app-maintenance" className={activeMenu === '/app-maintenance' ? 'active-menu' : ''}>App Maintenance Services</Link>
          </li>
        </ul>
      </li>
      <li className={parentMenu === 'seo' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
        <Link href="#" as="#">SEO Solutions</Link>
        <ul className="sub-menu">
          <li>
            <Link to="/seo/keyword-research" className={activeMenu === '/keyword-research' ? 'active-menu' : ''}>Keyword Research</Link>
          </li>
          <li>
            <Link to="/seo/on-page-optimization" className={activeMenu === '/on-page-optimization' ? 'active-menu' : ''}>On-Page Optimization</Link>
          </li>
        </ul>
      </li>
      <li className={parentMenu === 'support' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
        <Link href="#" as="#">Support & Maintenance</Link>
        <ul className="sub-menu">
          <li>
            <Link to="/support/technical-support" className={activeMenu === '/technical-support' ? 'active-menu' : ''}>Technical Support</Link>
          </li>
          <li>
            <Link to="/support/it-consulting" className={activeMenu === '/it-consulting' ? 'active-menu' : ''}>IT Consulting</Link>
          </li>
          <li>
            <Link to="/support/cybersecurity" className={activeMenu === '/cybersecurity' ? 'active-menu' : ''}>Cybersecurity</Link>
          </li>
        </ul>
      </li>
      <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
        <Link to="/contact" className={activeMenu === '/contact' ? 'active-menu' : ''}>Contact</Link>
      </li>
    </React.Fragment>
  )
}

export default MenuItems
