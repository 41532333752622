import React from 'react'
import Footer from '../../components/Layout/Footer'
import SearchModal from '../../components/Layout/Header/SearchModal'
import Header from '../../components/Layout/Header'
import ScrollToTop from '../../components/Common/ScrollTop'
import {Link} from 'react-router-dom'
import bannerImg from '../../assets/img/banner/banner-10.png'
import SectionTitle from '../../components/Common/SectionTitle'
import bgImg from '../../assets/img/bg/shape-bg2.png'
import SingleServiceTwo from '../../components/Service/SingleServiceTwo'
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png'
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png'
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png'
import hoverIcon2 from '../../assets/img/service/style2/hover-img/2.png'
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png'
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png'
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png'
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png'
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png'
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png'
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png'
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png'
import SingleProject from '../../components/Project/SingleProject'
import projectImg1 from '../../assets/img/project/style4/1.jpg'
import projectImg2 from '../../assets/img/project/style4/2.jpg'
import projectImg3 from '../../assets/img/project/style4/3.jpg'
import projectImg4 from '../../assets/img/project/style4/4.jpg'
import OwlCarousel from 'react-owl-carousel2'
import SingleTeam from '../../components/Team/SingleTeam'
import teamimg1 from '../../assets/img/team/1.jpg'
import teamimg2 from '../../assets/img/team/2.jpg'
import teamimg3 from '../../assets/img/team/3.jpg'
import teamimg4 from '../../assets/img/team/4.jpg'
import teamimg5 from '../../assets/img/team/5.jpg'
import teamimg6 from '../../assets/img/team/6.jpg'
import teamimg7 from '../../assets/img/team/7.jpg'
import SingleTestimonial6 from '../../components/Testimonial/SingleTestimonial6'
import author1 from '../../assets/img/testimonial/1.jpg'
import author2 from '../../assets/img/testimonial/2.jpg'
import author3 from '../../assets/img/testimonial/3.jpg'
import BrandThree from '../../components/Common/Brand/BrandThree'

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
}

const options = {
  items: 3,
  nav: false,
  dots: true,
  margin: 30,
  rewind: false,
  autoplay: false,
  stagePadding: 30,
  navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
  loop: true,
  center: false,
  responsive: {
    0: {
      stagePadding: 0, items: 1, dots: false,
    }, 768: {
      items: 2, stagePadding: 0, dots: true,
    }, 992: {
      items: 2, stagePadding: 0,
    }, 1200: {
      items: 3, stagePadding: 0,
    }, 1500: {
      items: 3, stagePadding: 0,
    },
  },
}

const options2 = {
  items: 1,
  nav: true,
  dots: false,
  margin: 30,
  rewind: false,
  autoplay: false,
  stagePadding: 30,
  navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
  loop: true,
  center: false,
  responsive: {
    0: {
      stagePadding: 0, items: 1, nav: false,
    }, 768: {
      items: 1, stagePadding: 0,

    }, 992: {
      items: 1, stagePadding: 0,

    }, 1200: {
      items: 1, stagePadding: 0,
    }, 1500: {
      items: 1, stagePadding: 0,
    },
  },
}

const PagesHome = () => {
  return (<React.Fragment>
      <Header
        parentMenu="home"
        secondParentMenu="multipage"
        activeMenu="/home-1"
      />
      <div className="rs-banner style6">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7">
              <div className="banner-content">
                <h1 className="title">Leading <span className="blue-color">Digital Technology,<br></br></span><span className="pink-color"> IT Solutions</span> & Professional
                  Services Worldwide
                </h1>
                <p className="desc">
                  With over 10 years of experience, we deliver innovative technology solutions to help businesses globally achieve digital transformation.
                </p>
                <div className="btn-part">
                  <Link className="readon started get-ready" to="/about">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="images-part">
          <img
            src={bannerImg}
            alt=""
          />
        </div>
      </div>
      {/*提供的服务*/}
      <div id="rs-service" className="rs-services style3 modify2 pt-100 pb-100 md-pt-74 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-44"
            subtitleClass="sub-text gold-color"
            subtitle="Our Services"
            titleClass="title title7"
            title="Key Services We Offer"
          />
          <div className="row">
            <div className="col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item"
                mainIcon={mainIcon1}
                hoverIcon={hoverIcon1}
                Title="Software Development"
                Text="Providing end-to-end software development services, from analysis and design to development and maintenance, tailored to each client’s needs."
              />
            </div>
            <div className="col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item pink-bg"
                mainIcon={mainIcon2}
                hoverIcon={hoverIcon2}
                Title="Web Development"
                Text="From web design to user experience optimization, we ensure your brand stands out in the digital space."
              />
            </div>
            <div className="col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item aqua-bg"
                mainIcon={mainIcon3}
                hoverIcon={hoverIcon3}
                Title="Analytics Solutions"
                Text="Leveraging advanced analytics to provide deep insights that drive informed business decisions."
              />
            </div>
            <div className="col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item paste-bg"
                mainIcon={mainIcon4}
                hoverIcon={hoverIcon4}
                Title="Cloud & DevOps"
                Text="Offering cloud solutions and DevOps services to accelerate product delivery and enhance resource management."
              />
            </div>
            <div className="col-md-6 sm-mb-20">
              <SingleServiceTwo
                itemClass="services-item purple-bg"
                mainIcon={mainIcon5}
                hoverIcon={hoverIcon5}
                Title="Product Design & UI"
                Text="Comprehensive product and UI design services to ensure exceptional user experiences."
              />
            </div>
            <div className="col-md-6">
              <SingleServiceTwo
                itemClass="services-item green-bg"
                mainIcon={mainIcon6}
                hoverIcon={hoverIcon6}
                Title="Database Administration"
                Text="Ensuring secure and reliable database management for robust business operations."
              />
            </div>
          </div>
        </div>
      </div>
      {/*价格表*/}
      <div id="rs-service" className="rs-services style3 modify2 pt-100 pb-100 md-pt-74 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-44"
            subtitleClass="sub-text gold-color"
            subtitle="Our Pricing"
            titleClass="title title7"
            title="Website complexity impacts design cost in Singapore"
          />
          <div className="pricing-table pt-80">
            <table className="table table-bordered text-center">
              <thead>
              <tr>
                <th><strong>Website Type</strong></th>
                <th><strong>Features Included</strong></th>
                <th><strong>Estimated Cost (SGD)</strong></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Landing Pages</td>
                <td>One-page design, mobile responsiveness, call-to-action (CTA) optimization</td>
                <td>500 – 1,500</td>
              </tr>
              <tr>
                <td>Portfolio Websites</td>
                <td>Image and video galleries, project showcases, contact form</td>
                <td>1,500 – 4,000</td>
              </tr>
              <tr>
                <td>Brochure Websites</td>
                <td>Basic pages, static content, contact information</td>
                <td>1,500 – 3,500</td>
              </tr>
              <tr>
                <td>Blog/Content Websites</td>
                <td>Blog management, commenting system, social sharing integration, SEO-friendly structure</td>
                <td>2,000 – 5,000</td>
              </tr>
              <tr>
                <td>Small Business Websites</td>
                <td>Additional pages, basic SEO, content management system (CMS) integration</td>
                <td>3,500 – 7,000</td>
              </tr>
              <tr>
                <td>Multi-Language Websites</td>
                <td>Language selection, localized SEO, multi-language CMS integration</td>
                <td>5,000 – 20,000</td>
              </tr>
              <tr>
                <td>Corporate Websites</td>
                <td>Custom design, advanced functionality, SEO optimization, CMS</td>
                <td>7,000 – 15,000+</td>
              </tr>
              <tr>
                <td>E-Commerce Websites</td>
                <td>Product catalogs, shopping carts, payment gateways, inventory systems</td>
                <td>10,000 – 25,000+</td>
              </tr>
              <tr>
                <td>Web Applications/Membership Sites</td>
                <td>User logins, databases, custom functionality, high interactivity</td>
                <td>15,000 – 50,000+</td>
              </tr>
              <tr>
                <td>AI-Powered Websites</td>
                <td>Chatbots, AI-based recommendations, personalized user experiences, advanced analytics</td>
                <td>20,000 – 70,000+</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/*作品展示*/}
      <div id="rs-portfolio" className="rs-project style6 modify2 gray-bg pt-90 pb-100 md-pt-74 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-44"
            subtitleClass="sub-text gold-color"
            subtitle="Our Projects"
            titleClass="title title7"
            title="Latest Projects We Delivered"
          />
          <div className="row">
            <div className="col-md-6 mb-40">
              <SingleProject
                itemClass="project-item"
                projectImage={projectImg1}
                Title="Growth Strategy Development"
                Category="Application Testing"
              />
            </div>
            <div className="col-md-6 mb-40">
              <SingleProject
                itemClass="project-item"
                projectImage={projectImg2}
                Title="Product Design"
                Category="Application Testing"
              />
            </div>
            <div className="col-md-6 md-mb-40">
              <SingleProject
                itemClass="project-item"
                projectImage={projectImg3}
                Title="Analytics Solutions"
                Category="Application Testing"
              />
            </div>
            <div className="col-md-6">
              <SingleProject
                itemClass="project-item"
                projectImage={projectImg4}
                Title="Product Engineering"
                Category="Application Testing"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="rs-team" className="rs-team pt-120 pb-94 md-pt-80 md-pb-54">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg white-color"
            subtitle="Our Team"
            titleClass="title white-color"
            title="Expert IT Consultants"
          />
          <OwlCarousel options={options}>
            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg1}
              Title="Makhaia Antitni"
              Designation="President & CEO"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg2}
              Title="Corey Anderson"
              Designation="CEO & Founder"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg3}
              Title="Masud Rana"
              Designation="Web Developer"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg4}
              Title="Najmul Huda"
              Designation="Digital Marketer"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg5}
              Title="Rushali Rumi"
              Designation="Design Lead"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg6}
              Title="Abu Sayed"
              Designation="App Developer"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamimg7}
              Title="Sonia Akhter"
              Designation="Graphic Artist"
            />
          </OwlCarousel>
        </div>
      </div>
      <div className="rs-testimonial style7 pt-100 pb-80 md-pt-74" style={bgStyle}>
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-20"
            subtitleClass="sub-text gold-color"
            subtitle="Client Testimonials"
            titleClass="title title7"
            title="What Our Clients Are Saying"
          />
          <div className="testi-main">
            <OwlCarousel options={options2}>
              <SingleTestimonial6
                itemClass="testi-item"
                authorImage={author1}
                Title="David Warner"
                Designation="Web Developer"
                Description="Through premium technical support and comprehensive service, we assist our clients in succeeding on their digital journey."
              />
              <SingleTestimonial6
                itemClass="testi-item"
                authorImage={author2}
                Title="David Warner"
                Designation="Web Developer"
                Description="Through premium technical support and comprehensive service, we assist our clients in succeeding on their digital journey."
              />
              <SingleTestimonial6
                itemClass="testi-item"
                authorImage={author3}
                Title="David Warner"
                Designation="Web Developer"
                Description="Through premium technical support and comprehensive service, we assist our clients in succeeding on their digital journey."
              />
            </OwlCarousel>
          </div>
        </div>
        <BrandThree
          brandClass="rs-partner style4 pt-70"
        />
      </div>
      <ScrollToTop/>
      <Footer
        footerClass="rs-footer"
      />
      <SearchModal/>
    </React.Fragment>
  )
}

export default PagesHome
